import{lazy} from 'react';
const ItemCategory = lazy(()=>import("../../views/MasterDataset/ItemCategory/itemCategory"))
const Parties = lazy(()=>import("../../views/Parties/Parties"))
const Items = lazy(()=>import("../../views/Items/Items"))
const Purchase = lazy(()=>import("../../views/Purchase/Purchase"))
const Sales = lazy(()=>import("../../views/Sales/Sales"))
const StockSummary = lazy(()=>import("../../views/Reports/StockSummary"))
const MonthlySheet = lazy(()=>import("../../views/MonthlySheet/monthlySheet"))
const Dashboard = lazy(()=>import('../../views/Dashboard/Dashboard'))
export const Routes = [   
    {
        path:"/Parties",
        component:Parties,
        exact:true
    },
    {
        path:"/Items",
        component:Items,
        exact:true
    },
    {
        path:"/Purchase",
        component:Purchase,
        exact:true
    },
    
    {
        path:"/Sales",
        component:Sales,
        exact:true
    },
    {
        path:"/MasterDataSet/UnitList",
        component:ItemCategory,
        exact:true
    },
    {
        path:"/ItemCategoryList",
        component:ItemCategory,
        exact:true
    },
    {
        path:"/StockSummary",
        component:StockSummary,
        exact:true
    },
    {
        path:"/MonthlySheet",
        component:MonthlySheet,
        exact:true
    },
    {
        path:"/Dashboard",
        component:Dashboard,
        exact:true
    },
    
    
];
export default Routes;