import React, { Suspense,useState,useEffect } from 'react';
import './App.css';
import { ToastContainer } from 'react-toastify';
import Baselayout from './layouts/Baselayout';
import { Loader }  from './layouts/Loader'
import Login from './views/Auth/Login';
import { HashRouter } from 'react-router-dom'

function App() {
 
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('isLoggedIn') ===  'true' ? true : false)
  const [isOnline, setNetwork] = useState(window.navigator.onLine);
  function setLoggedValue(value) {
    setIsLoggedIn(value)
  }
  
    const updateNetwork = () => {
        setNetwork(window.navigator.onLine);
     };
     useEffect(() => {
        window.addEventListener("offline", updateNetwork);
        window.addEventListener("online", updateNetwork);
        return () => {
           window.removeEventListener("offline", updateNetwork);
           window.removeEventListener("online", updateNetwork);
        };
     });
     if(!isOnline){
       
     }
     
  return (
    <React.Fragment>
    
      <HashRouter>
        <ToastContainer />
        <Suspense fallback={<Loader />} >
          {
          !isLoggedIn ? <Login setLoggedValue={setLoggedValue} /> :
          <Baselayout isLoggedIn={isLoggedIn} setLoggedValue={setLoggedValue}/>
          }
        </Suspense>
      </HashRouter>
    
      
    </React.Fragment>
  );
}

export default App;
