var environment = {}
if (window.location.hostname === 'localhost') {
    environment.baseUrl = "http://127.0.0.1:8000/api"
    
}else{
    environment.baseUrl = "//api.aiswaryajewellersmart.com/public/api"
}
export default environment;

export const APIUrl = {
    SignUp: "/register",
    SignIn: "/login",
    UserCheck: "/userCheck",
    ITEMCATEGORY: "/itemCategory",
    ITEMCATEGORYCHECK: "/itemCategoryCheck",
    PARTIES: "/parties",
    PARTIESCHECK: "/partiesCheck",
    ITEM: "/item",
    ITEMSEARCH: "/itemSearch",
    PURCHASE: "/purchase",
    VOUCHERCREATE: "/voucherCreate",
    INVOICE: "/invoice",
    STOCKREPORT: "/itemStockReport",
    MONTHLYSHEET: "/monthlySheet",
    MONTHLYSHEET_CREATE: "/monthlySheetCreate",
    MONTHLYSHEET_UPDATE: "/monthlySheetStatusUpdate",
    TODAYRATE_READ: "/getTodayRate",
    TODAYRATE_UPDATE: "/updateTodayRate"

}