function Loader(){
    return (
        <div className="jumping-dots-loader">
         <span></span>
         <span></span>
        <span></span>
        </div>
    );
}

function ItemLoader(){

    return (
        <>
            <img src={process.env.PUBLIC_URL + '/itemLoader.gif'}  alt="itemLoading"  style={{ height:"30%"}}/>
        </>
    )
}

export { Loader, ItemLoader };
