import React, { useState } from 'react';

const initialState = {
    todayPrice:{},
    userDetails:{}
}

const defaultContextValues = {
    ...initialState,
    setPrice: async ()=>{},
    setUserDetails:async ()=>{}
}

const AuthContext = React.createContext(defaultContextValues);



const AuthProvider = ({ children }) => {
  
    const [authState,setAuthState]=useState(initialState);


    const setPrice = (price)=>{
        setAuthState({
            ...authState,
            todayPrice:price
        })
    }

    const setUserDetails = ()=>{

    }
  
    return (
      <AuthContext.Provider value={{
        ...authState,
        setPrice,
        setUserDetails
      }}>
        {children}
      </AuthContext.Provider>
    );
  };
  

const useAuth =()=> React.useContext(AuthContext);

  export {
    AuthProvider,
    useAuth
  };

