import React, { useState, useRef } from "react";
import $ from 'jquery';
import { useForm } from "react-hook-form";
import { ToastContainer } from 'react-toastify';
import { useHistory } from "react-router-dom";
import { services } from '../../services/services'
import { APIUrl } from '../../helpers/config';


function Login(props) {

    let history = useHistory();
    const [userCheckForm, setuserCheckForm] = useState(true);
    const [loginForm, setloginForm] = useState(false);
    const [registerForm, setregisterForm] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        trigger,
        watch
    } = useForm();
    const password = useRef({});
    password.current = watch("password", "");

    const passwordmatch = async (conform_password) => {
        if (conform_password === password.current) {
            return true;
        } else {
            return false;
        }
    };


    const onUserCheckSubmit = (async (data,e) => {
        
        // $(".btn-primary .fa-spin").show();
        $(".btn-primary .btn-text").html("  Loading");
        $(".btn-primary").attr('disabled', 'disabled');
        services.post(APIUrl.UserCheck, data).then(result => {
            if (result && result.data) {
                // $(".btn-primary .fa-spin").hide();
                setuserCheckForm(false)
                if (result.data.status) {
                    setloginForm(true)
                    $(".btn-primary").removeAttr('disabled');
                } else {
                    setloginForm(true);
                    setregisterForm(true);
                    $(".btn-primary").removeAttr('disabled');

                }
            }
        });
    });

    const onloginSubmit = (async (data) => {
        $(".btn-primary .btn-text").html("  Loading");
        $(".btn-primary").attr('disabled', 'disabled');
      
        const url = (loginForm && registerForm) ? APIUrl.SignUp : APIUrl.SignIn
        services.post(url, data).then(response => {
            $(".btn-primary").removeAttr('disabled');
            $(".btn-primary .btn-text").html("SIGNIN");
            if (response && response.status) {
                let res = response.data
                setuserCheckForm(true)
                setloginForm(false)
                setregisterForm(false)
                localStorage.setItem('isLoggedIn', true)
                localStorage.setItem('token', res.data.token)
                localStorage.setItem('localData', JSON.stringify(res.data))
                props.setLoggedValue(true)
                history.push("/Dashboard");
            }
        });
    });

    const onBack = (() => {
        reset();
        setuserCheckForm(true)
        setloginForm(false)
        setregisterForm(false)
    });

    $('#loginForm').on('keyup keypress', function(e) {
        var keyCode = e.keyCode || e.which;
        if (keyCode === 13) { 
          e.preventDefault();
          return false;
        }
      });

    return (
        <div className="container-scroller">
            <ToastContainer />
            <div className="container-fluid page-body-wrapper full-page-wrapper">
                <div className="content-wrapper d-flex align-items-center auth px-0">
                    <div className="row w-100 mx-0">

                        <div className="col-lg-4 mx-auto">

                            <div className="auth-form-light text-left py-5 px-4 px-sm-5">


                                <div className="brand-logo">
                                    {/* <img src="{process.env.PUBLIC_URL + 'theme/images/logo.svg'}" alt="logo" /> */}
                                </div>


                                <h4>Hello! let's get started</h4>
                                <h6 className="font-weight-light">Sign  {registerForm ? "up" : "in"} to continue.</h6>

                                <form className="pt-3" onSubmit={handleSubmit(userCheckForm ? onUserCheckSubmit : loginForm ? onloginSubmit : onloginSubmit)} autoComplete="off"  id="loginForm">
                                    {userCheckForm &&
                                        <div className="form-group">
                                            <input
                                                type="number"
                                                className={`form-control form-control-lg ${errors.email && "invalid"}`}
                                                id="exampleInputMobile"
                                                placeholder="Mobile"
                                                name="mobile"
                                                {...register("mobile", { required: true, minLength: 10, maxLength: 10 })}
                                                onKeyUp={() => {
                                                    trigger("mobile");
                                                }}
                                            />
                                            {errors.mobile?.type === 'required' && (
                                                <small className="text-danger">*MobileNo is required</small>
                                            )}
                                            {errors.mobile?.type === 'minLength' && (
                                                <small className="text-danger">*Invalid MobileNo</small>
                                            )}
                                            {errors.mobile?.type === 'maxLength' && (
                                                <small className="text-danger">*Invalid MobileNo</small>
                                            )}
                                        </div>
                                    }
                                    {loginForm &&
                                        <div className="form-group"  >
                                            <input
                                                type="password"
                                                className={`form-control form-control-lg ${errors.password && "invalid"}`}
                                                placeholder=" Password"
                                                name="password"
                                                {...register("password", { required: true, minLength: registerForm ? 8 : '' })}
                                                onKeyUp={() => {
                                                    trigger("password");
                                                }}
                                            />
                                            {errors.password?.type === 'required' && (
                                                <small className="text-danger">*Password is required</small>
                                            )}
                                            {errors.password?.type === 'minLength' && (
                                                <small className="text-danger">*Password should be minimanm 8 digit</small>
                                            )}
                                        </div>
                                    }
                                    {registerForm &&
                                        <div className="form-group">
                                            <input
                                                type="password"
                                                className={`form-control form-control-lg ${errors.password && "invalid"}`}
                                                placeholder="Conform Password"
                                                id="exampleInputPassword1"
                                                name="conformpassword"
                                                {...register("conformpassword", { required: true, validate: passwordmatch })}
                                                onKeyUp={() => {
                                                    trigger("conformpassword");
                                                }}
                                            />
                                            {errors.conformpassword?.type === 'required' && (
                                                <small className="text-danger">*Conform Password is required</small>
                                            )}
                                            {errors.conformpassword?.type === 'validate' && (
                                                <small className="text-danger">*Passwords does not match!!</small>
                                            )}
                                        </div>
                                    }
                                    <div className="mt-3">
                                        <button className="btn btn-block btn-primary mr-2"><div>
                                            {/* <i className="fa fa-spinner fa-spin" />&nbsp; */}
                                            <span className="btn-text">{userCheckForm ? "NEXT" : loginForm && registerForm ? "SIGNUP" : "SIGNIN"}</span>
                                        </div>
                                        </button>
                                        {/* <button className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn">{userCheckForm ? "NEXT" : loginForm && registerForm ? "SIGNUP" : "SIGNIN"}</button> */}
                                    </div>
                                    {loginForm &&
                                        <div className="text-center mt-4 font-weight-light">
                                            {loginForm && registerForm ? "Already" : "Don't"}  have an account? <a href="#/" onClick={onBack} className="text-primary" style={{ cursor: 'pointer' }}>back</a>
                                        </div>
                                    }
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Login;