import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React, { useState, useEffect } from "react";
import { APIUrl } from '../helpers/config';
import { services } from '../services/services';
import { utils as utility } from '../helpers/utils';
import { useAuth } from '../views/Hook/AuthContext';



function Navbar(props) {

    const { setPrice, todayPrice } = useAuth();

    useEffect(() => {
        const fetchData = async () => {
            services.get(APIUrl.TODAYRATE_READ).then(response => {
                if (response && response.data) {
                    const { data } = response.data;
                    if (data) {
                        setPrice(data);
                    }
                }
            });
        };

        fetchData();
    }, []);


    const logout = () => {
        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("token");
        localStorage.removeItem("localData");
        toast.success("Logout Sucessfully!!");
        props.setLoggedValue(false)

    };
    // const redirect = () => {
    //     history.push("/Dashboard");
    // };
    let url = "#/";
    return (
        <nav className="navbar col-lg-12 col-12 p-0 fixed-top">
            <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
                <a className="navbar-brand brand-logo mr-5" href={url}>
                    AJM&nbsp;&nbsp;
                    <img src={process.env.PUBLIC_URL + '/AJM.jpeg'} className="mr-2" alt="logo" />
                </a>
                <a className="navbar-brand brand-logo-mini" href={url}>

                    <img src={process.env.PUBLIC_URL + '/AJM.jpeg'} className="mr-2" alt="logo" />
                </a>
            </div>
            <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">
                <button className="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize">
                    <span className="icon-menu"></span>
                </button>
                {
                    todayPrice['gold_rate'] !== undefined && todayPrice['silver_rate']!== undefined &&
                    <marquee className="marquee" style={{ color: "green" }} scrollamount="2">{utility.convertDateFormat(todayPrice.updated_at)}<sup>on</sup> Gold-{todayPrice.gold_rate}/gm,Silver-{todayPrice.silver_rate}/gm</marquee>
                }
                <ul className="navbar-nav navbar-nav-right">
                    <li className="nav-item nav-profile dropdown">

                        <a className="nav-link dropdown-toggle" href={url} data-toggle="dropdown" id="profileDropdown">
                            <img src={process.env.PUBLIC_URL + '/userPic.jpeg'} alt="profile" />
                        </a>
                        <div className="dropdown-menu dropdown-menu-top navbar-dropdown" aria-labelledby="profileDropdown">
                            <a className="dropdown-item" href={url}>
                                <i className="ti-settings text-primary"></i>
                                Settings
                            </a>
                            <a className="dropdown-item" href={url} onClick={logout}>
                                <i className="ti-power-off text-primary"></i>
                                Logout
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
        </nav>
    );
}
export default Navbar;