/**
 * Converts a string representation of a number to an actual numeric value.
 * 
 * @param {string} val - The string to be converted to a number.
 * @returns {number} The numeric value parsed from the input string.
 */
function stringToNumber(val) {
    return parseInt(val);
}


/**
 * Handles the selection of an item by transforming and modifying the provided data object.
 * Renames 'id' property to 'item_id', sets 'totalAmt' to 0, and removes unnecessary properties
 * related to database and UI representation.
 * 
 * @param {object} data - The data object representing the selected item.
 * @returns {object} The modified data object with relevant properties for further processing.
 */
function itemSelectHandler(data) {
    // Rename 'id' property to 'item_id'
    data.item_id = data.id;

    // Set 'totalAmt' to 0
    data.totalAmt = 0;

    // Remove unnecessary properties related to database and UI representation
    delete data.created_by;
    delete data.updated_by;
    delete data.deleted_by;
    delete data.createdAt;
    delete data.updatedAt;
    delete data.item_category_id;
    delete data.code_type;
    delete data.qty;
    delete data.shop_id;
    delete data.id;

    return data;
}


/**
 * Handles the change in per gram price for a specific item, updating relevant properties
 * and recalculating total amounts within the item array.
 * 
 * @param {Array} item - The array of items containing per gram price, weight, and total amounts.
 * @param {string} itemId - The unique identifier of the item whose per gram price is changing.
 * @param {number} price - The new per gram price for the specified item.
 * @returns {number} The updated total sum of all item amounts.
 */
function gmPriceChange(item, itemId, price) {
    // Find the index of the item in the array
    const itemIndex = item.findIndex(p => p.item_id === itemId);

    // Update per gram price, total amount, and subtotals
    item[itemIndex].per_gm_price = price;
    item[itemIndex].totalAmt = item[itemIndex].weight * price;
    item[itemIndex].sub_total = item[itemIndex].weight * price;
    item[itemIndex].overall_total = item[itemIndex].weight * price;

    // Recalculate the total sum of all item amounts
    const total = sumOfTotalAmount(item);

    return total;
}

/**
 * Handles the change in total amount for a specific item, updating relevant properties
 * such as per gram price, total amount, subtotals, and recalculating overall totals within the item array.
 * 
 * @param {Array} item - The array of items containing per gram price, weight, and total amounts.
 * @param {string} itemId - The unique identifier of the item whose total amount is changing.
 * @param {number} price - The new total amount for the specified item.
 * @returns {number} The updated total sum of all item amounts.
 */
function totalAmtChange(item, itemId, price) {
    // Find the index of the item in the array
    const itemIndex = item.findIndex(p => p.item_id === itemId);

    // Update per gram price based on the new total amount and weight
    item[itemIndex].per_gm_price = parseFloat(price / item[itemIndex].weight).toFixed(2);

    // Update total amount, subtotals, and overall total
    item[itemIndex].totalAmt = price;
    item[itemIndex].sub_total = price;
    item[itemIndex].overall_total = price;

    // Recalculate the total sum of all item amounts
    const total = sumOfTotalAmount(item);

    return total;
}


/**
 * Determines the status code based on the due amount, overall total, and paid amount.
 * 
 * Status Codes:
 * - 0: Fully Due (Due amount is equal to overall total)
 * - 1: Partially Paid (Paid amount is not equal to overall total, and due amount is not equal to overall total)
 * - 2: Fully Paid (Paid amount is equal to overall total)
 * 
 * @param {number} dueAmount - The amount that is yet to be paid.
 * @param {number} overallTotal - The total amount of the transaction or obligation.
 * @param {number} paidAmount - The amount that has already been paid.
 * @returns {number} The status code indicating the payment status.
 */
function onStatus(dueAmount, overallTotal, paidAmount) {
    return (dueAmount === overallTotal) ? 0 : paidAmount === overallTotal ? 2 : 1;
}


/**
 * Prepares data for submission by removing unnecessary properties related to database records
 * and UI representation before sending it to a server or processing function.
 * 
 * @param {object} data - The data object to be submitted.
 * @returns {object} The modified data object with irrelevant properties removed.
 */
function onSubmit(data) {
    // Remove unnecessary properties related to database records and UI representation
    delete data.created_by;
    delete data.updated_by;
    delete data.deleted_by;
    delete data.createdAt;
    delete data.updatedAt;
    delete data.id;
    delete data.shop_id;

    return data;
}


/**
 * Calculates the sum of total amounts from an array of items.
 * 
 * @param {Array} item - The array of items, each containing a 'totalAmt' property.
 * @returns {number} The total sum of 'totalAmt' values across all items.
 */
function sumOfTotalAmount(item) {
    // Using reduce to calculate the sum of 'totalAmt' values in the array
    const total = item.reduce(function (sum, current) {
        return sum + current.totalAmt;
    }, 0);

    return total;
}


/**
 * Retrieves the value of a specified parameter from the URL query string.
 * 
 * @param {string} name - The name of the parameter to retrieve.
 * @param {string} url - Optional. The URL containing the query string. Defaults to the current window location.
 * @returns {string|null} The value of the specified parameter, or null if the parameter is not found.
 */
function getParameterByName(name, url = window.location.href) {
    // Escape special characters in the parameter name for use in the regular expression
    name = name.replace(/[[\]]/g, '\\$&');

    // Construct a regular expression to match the parameter in the URL
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');

    // Execute the regular expression on the URL to find the parameter and its value
    var results = regex.exec(url);

    // If the parameter is not found, return null
    if (!results) return null;

    // If the parameter has no value, return an empty string
    if (!results[2]) return '';

    // Decode and return the parameter value, replacing '+' with spaces
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}


/**
 * Creates a debounced version of a function that delays its invocation until after a specified timeout.
 * 
 * @param {Function} func - The function to be debounced.
 * @returns {Function} A debounced version of the input function.
 */
function debounce(func) {
    // Initialize a timer variable to track the delay
    let timer;

    // Return a debounced version of the function
    return function (...args) {
        // Preserve the context of the original function
        const context = this;

        // If a timer is already running, clear it to restart the delay
        if (timer) clearTimeout(timer);

        // Set a new timer to invoke the function after a specified timeout (500 milliseconds in this case)
        timer = setTimeout(() => {
            // Reset the timer and invoke the original function with the provided arguments and context
            timer = null;
            func.apply(context, args);
        }, 500);
    };
}


/**
 * Generates the latest sheet number based on the provided input.
 * If the input is empty, returns the initial sheet number for the current year.
 * Otherwise, increments the numeric part and, if needed, the alphabetic part of the input sheet number.
 * 
 * @param {string} input - The input sheet number to be incremented.
 * @returns {string} The latest sheet number.
 */
function getLatestSheetNo(input) {


    let isNewYearDay = isNewYear();

    // Get the last two digits of the current year
    const currentYearLastTwoDigit = getCurrentYearLastTwoDigit();
    // If input is empty, return the initial sheet number for the current year
    if (!input.length || isNewYearDay) {
        return `001A${currentYearLastTwoDigit}`;
    }

    if (input.length === 6 || input.length === 7) {
        // Remove the last two digits using the slice method
        input = input.slice(0, -2);
    }


    // Find the index where alphabetic characters start
    let alphaIndex;
    for (let i = 0; i < input.length; i++) {
        if (isNaN(input[i])) {
            alphaIndex = i;
            break;
        }
    }

    // If alphabetic characters are found in the input
    if (typeof alphaIndex !== 'undefined') {
        let numericPart = parseInt(input.substring(0, alphaIndex));
        let alphabeticPart = input.substring(alphaIndex);

        // Check if numeric part is less than 1000
        if (numericPart < 1000) {
            // Increment the numeric part within the limit
            numericPart++;
        } else {
            // Reset numeric part and increment alphabetically
            numericPart = 1;
            alphabeticPart = String.fromCharCode(alphabeticPart.charCodeAt(0) + 1);
        }

        // Pad the numeric part with leading zeros and combine with the alphabetic part
        const incrementedValue = `${numericPart.toString().padStart(3, '0')}${alphabeticPart}${currentYearLastTwoDigit}`;

        return incrementedValue;
    } else {
        return `001A${currentYearLastTwoDigit}`;
    }
}



/**
 * Checks if today is the first day of the year (January 1st).
 * 
 * @returns {boolean} True if today is New Year's Day, otherwise false.
 */
function isNewYear() {
    // Get the current date
    const today = new Date();

    // Check if the current month is January (0-based) and the day is 1
    const isNewYearDay = today.getMonth() === 0 && today.getDate() === 1;

    return isNewYearDay;
}




/**
 * Retrieves the last two digits of the current year.
 * 
 * @returns {number} The last two digits of the current year.
 */
function getCurrentYearLastTwoDigit() {
    // Get the current date
    const currentDate = new Date();

    // Get the current year
    const currentYear = currentDate.getFullYear();

    // Extract the last two digits
    const lastTwoDigits = currentYear % 100;

    return lastTwoDigits;
}

/**
 * Sends a monthly sheet-related WhatsApp message to a specified phone number.
 * 
 * @param {string} toNumber - The recipient's phone number.
 * @param {string} month - The month for which the sheet is related.
 * @param {number} amount - The amount associated with the monthly sheet.
 * @param {string} sheet_no - The sheet number corresponding to the monthly data.
 */
function monthlySheetPaymentMsgPrepare(toNumber, month, amount, sheet_no, isGiftIssued = false) {

    var content = "";
    if (isGiftIssued) {
        content = `உங்கள் அட்டை எண்ணில் ${sheet_no},${month} மாதத்தொகை ரூபாய் ${amount} வரவு வைக்கப்பட்டுள்ளது. மற்றும் தொடக்க பரிசு வழங்கப்பட்டது`;
    } else {
        content = `உங்கள் அட்டை எண்ணில் ${sheet_no},${month} மாதத்தொகை ரூபாய் ${amount} வரவு வைக்கப்பட்டுள்ளது`;
    }



    // Message content for the WhatsApp message
    const message = `அன்பார்ந்த வாடிக்கையாளரே,
${content}.பணம் செலுத்தியதற்கு நன்றி.

இப்படிக்கு
ஐஸ்வர்யா ஜூவல்லர்ஸ் மார்ட்,
தபால் அலுவலக தெரு,
விஜயா மெட்டல் அருகில்,
பெரம்பலூர்.
Contact-9842232852.
Website- https://aiswaryajewellersmart.com
facebook- https://www.facebook.com/AiswaryaJewellersMart,
instagram-https://www.instagram.com/aiswarya_jewellers_mart`;

    redirectWhatsAppMessage(toNumber, message);

}


/**
 * Sends a monthly sheet-related WhatsApp message to a specified phone number.
 * 
 * @param {string} toNumber - The recipient's phone number.
 * @param {string} month - The month for which the sheet is related.
 * @param {number} amount - The amount associated with the monthly sheet.
 * @param {string} sheet_no - The sheet number corresponding to the monthly data.
 */
function monthlySheetStatusUpdateMsgPrepare(toNumber, sheet_no, statusChanges) {

    var msg = [];
    if (!statusChanges.gift) {
        msg.push("தொடக்க பரிசு வழங்கப்பட்டது");
    }
    if (!statusChanges.things) {
        msg.push("அனைத்து பொருட்களும் வழங்கப்பட்டது");
    }

    if (!statusChanges.general) {
        msg.push("தொடக்க பரிசு மற்றும் அனைத்து பொருட்களும் வழங்கப்பட்டது.உங்களின் அட்டை முற்றிலுமாக முடிவடைந்தது")
    }
    // Message content for the WhatsApp message
    const message = `அன்பார்ந்த வாடிக்கையாளரே,
உங்கள் அட்டை எண்ணுக்கு ${sheet_no},
${msg.join()}.
உங்களின் ஆதரவுக்கு நன்றி.

இப்படிக்கு
ஐஸ்வர்யா ஜூவல்லர்ஸ் மார்ட்,
தபால் அலுவலக தெரு,
விஜயா மெட்டல் அருகில்,
பெரம்பலூர்.
Contact-9842232852.
Website- https://aiswaryajewellersmart.com
facebook- https://www.facebook.com/AiswaryaJewellersMart,
instagram-https://www.instagram.com/aiswarya_jewellers_mart`;

    redirectWhatsAppMessage(toNumber, message);

}




function redirectWhatsAppMessage(toNumber, message) {
    // Open a new window with the WhatsApp link and pre-filled message
    window.open(`https://wa.me/${toNumber}?text=${encodeURIComponent(message)}`, "_blank");
}

/**
 * Formats a given date object in the Indian date format (yyyy-mm-dd).
 * 
 * @param {Date|string} date - The date object or date string to be formatted.
 * @returns {string} The formatted date string in the "yyyy-mm-dd" format.
 */
function formatDateIndian(inputDate) {
    // Ensure inputDate is not null or undefined
    if (!inputDate) {
        console.error('Input date is null or undefined.');
        return null; // Or throw an error, depending on your use case
    }

    var isValidDate = !isNaN(Date.parse(inputDate));
    if (!isValidDate) {
        console.error('Invalid date values.');
        return null; // Or throw an error, depending on your use case
    }

    // Split the date string into day, month, and year
    var dateParts = inputDate.split('-');
    // console.log(dateParts);
    // Check if the split resulted in three parts
    if (dateParts.length !== 3) {
        console.error('Invalid date format. Expected "dd-mm-yyyy".');
        return null; // Or throw an error, depending on your use case
    }

    // Rearrange the date parts to form the "yyyy-mm-dd" format
    var convertedDate = dateParts[2] + '-' + dateParts[1] + '-' + dateParts[0];
    // Validate the rearranged date


    return convertedDate;
}


/**
 * Formats a given date object in the Indian date format (yyyy-mm-dd).
 * 
 * @param {Date|string} date - The date object or date string to be formatted.
 * @returns {string} The formatted date string in the "yyyy-mm-dd" format.
 */
function formatDateUS(inputDate) {
    // Ensure inputDate is not null or undefined
    if (!inputDate) {
        console.error('Input date is null or undefined.');
        return null; // Or throw an error, depending on your use case
    }

    // Split the date string into day, month, and year
    var dateParts = inputDate.split('-');

    // Check if the split resulted in three parts
    if (dateParts.length !== 3) {
        console.error('Invalid date format. Expected "dd-mm-yyyy".');
        return null; // Or throw an error, depending on your use case
    }

    // Rearrange the date parts to form the "dd-mm-yyy" format
    var convertedDate = dateParts[2] + '-' + dateParts[1] + '-' + dateParts[0];

    // Validate the rearranged date
    var isValidDate = !isNaN(Date.parse(convertedDate));
    if (!isValidDate) {
        console.error('Invalid date values.');
        return null; // Or throw an error, depending on your use case
    }

    return convertedDate;
}

// format 1 means Date and Time 2 means Date 
function formatIndianDateToISOFormat(timestamp, format) {
    try {
        // // Create a new Date object from the ISO timestamp
        // const date = new Date(timestamp);

        // // Get the day, month, and year
        // const day = String(date.getDate()).padStart(2, '0');
        // const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        // const year = date.getFullYear();

        // // Get the hours, minutes, and seconds
        // const hours = String(date.getHours()).padStart(2, '0');
        // const minutes = String(date.getMinutes()).padStart(2, '0');

        // // Determine AM or PM
        // const ampm = hours >= 12 ? 'PM' : 'AM';

        // // Return the formatted date and time in DD-MM-YYYY HH:MM:SS format
        // if(format === 1){
        //     return `${day}-${month}-${year} ${hours}:${minutes} ${ampm}`;
        // }else{
        //     return `${day}-${month}-${year}`;
        // }
        // Create a new Date object from the ISO timestamp


        // Create a new Date object from the ISO timestamp
        // Create a new Date object from the ISO timestamp
        const date = new Date(timestamp);

        // Convert the time to IST (Indian Standard Time)
        // IST is 5 hours and 30 minutes ahead of UTC
        const istOffset = 5 * 60 + 30; // IST offset in minutes
        const utcOffset = date.getTimezoneOffset(); // Local timezone offset in minutes
        const istDate = new Date(date.getTime() + (istOffset - utcOffset) * 60 * 1000);

        // Get the day, month, and year
        const day = String(istDate.getDate()).padStart(2, '0');
        const month = String(istDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = istDate.getFullYear();

        // Get the hours, minutes, and seconds
        let hours = istDate.getHours();
        const minutes = String(istDate.getMinutes()).padStart(2, '0');
        const seconds = String(istDate.getSeconds()).padStart(2, '0');

        // Determine AM or PM
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // The hour '0' should be '12'
        const formattedHours = String(hours).padStart(2, '0');

        // Format the date and time
        const formattedDate = `${day}-${month}-${year}`;
        const formattedTime = `${formattedHours}:${minutes}:${seconds} ${ampm}`;

        if(format === 1){
        return `${formattedDate} ${formattedTime}`;
        }else{
            return `${formattedDate}`;
        }



    } catch (error) {
        return "";
    }

}

function convertDateFormat(dateString) {
    // Parse the date string into a Date object
    let date = new Date(dateString);
  
    let year = date.getFullYear();
    let month = date.getMonth() + 1; // Months are zero-based
    let day = date.getDate();
  
    // Pad month and day with leading zeros if necessary
    if (month < 10) {
      month = '0' + month;
    }
    if (day < 10) {
      day = '0' + day;
    }
  
    return `${day}-${month}-${year}`;
  }
  

  







export const utils = {
    getParameterByName,
    stringToNumber,
    itemSelectHandler,
    gmPriceChange,
    totalAmtChange,
    onStatus,
    onSubmit,
    sumOfTotalAmount,
    debounce,
    getLatestSheetNo,
    monthlySheetPaymentMsgPrepare,
    monthlySheetStatusUpdateMsgPrepare,
    formatDateIndian,
    formatDateUS,
    formatIndianDateToISOFormat,
    convertDateFormat
}



