import  environment  from '../helpers/config.js';
import { toast } from 'react-toastify';
import axios from 'axios';

// GET Method
function get(url) {

    const requestOptions = {
        headers: {
            'type': 'web',
            "Access-Control-Allow-Origin": "*",
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        }
    };

    return axios.get(environment.baseUrl + url,requestOptions)
        .then((result) => {
            return result.status === 200 ? result : false;
        }).catch((err) => {
            
            if(err && err.response && err.response.status === 500){
                toast.error(err.response.data.message);
            }else if(err && err.response && err.response.status === 401){
                toast.warning(err.response.data.message);
                resetSessions();
            }else if(err && err.response && err.response.status === 422){
                toast.warning(err.response.data.message)
            }else{
                toast.error("Internal Server Error,Contact Admin")
            }
            return false;
        });
}
// Post Method
function post(url, data) {

    const requestOptions = {
        headers: {
            'type': 'web',
            "Access-Control-Allow-Origin": "*",
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        }
    };

    return axios.post(environment.baseUrl + url, data, requestOptions)
        .then((result) => {
            return result.status === 200 ? result : false;
        }).catch((err) => {
            if(err && err.response && err.response.status === 400){
                toast.warning(err && err.response && err.response.data.message)
            }else{
                toast.error(err && err.response && err.response.status !== 500 ? err && err.response && err.response.data.message : "Internal Server Error,Contact Admin");
            }
            return false;
        });
}

function put(url, data) {

    const requestOptions = {
        headers: {
            'type': 'web',
            "Access-Control-Allow-Origin": "*",
            'Content-Type': 'application/json;charset=UTF-8',
            'Authorization': 'Bearer ' + localStorage.getItem('token'),
        }
    };

    return axios.put(environment.baseUrl + url, data, requestOptions)
        .then((result) => {
            return result.status === 200 ? result : false;
        }).catch((err) => {
            
            toast.error(err.response.status !== 500 ? err.response.data.message : "Internal Server Error,Contact Admin");
            return false;
        });
}


function resetSessions(){
    localStorage.removeItem('isLoggedIn')
    localStorage.removeItem('localData')
    localStorage.removeItem('token')
    setTimeout(()=>{
        window.location.href="/";
    },1000)
}



export const services = {};
services.get = get;
services.post = post;
services.put = put;
