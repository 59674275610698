import React from 'react'
import { Menu } from './sidemenu/Menu';
import { Link } from 'react-router-dom';
import { useLocation,useHistory } from "react-router-dom";


function Sidebar () {

    let history = useHistory();
    const location = useLocation();

    const isLogined = localStorage.getItem('isLoggedIn') ? true :false;
    //destructuring pathname from location
    const { pathname } = location;
    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/");
    var currentpath = `/${splitLocation[1]}`;
    currentpath = currentpath === "/" ? history.push("/Price"):currentpath

    return (
        
        <React.Fragment>
            <nav className="sidebar sidebar-offcanvas" id="sidebar">
                <ul className="nav">
                    {Menu.map((menu, index) =>
                        menu.fixmenu ? (
                            <li
                                className={`nav-item ${currentpath === menu.path ? "active" : ""
                                    }`}
                                key={index}
                            >
                                <Link to={isLogined ? menu.path:"/login"} className={`nav-link ${menu.path}}`}>
                                    <i className={menu.icon}></i>
                                    <span className="menu-title" style={{fontWeight:'bold'}}>{menu.name}</span>
                                </Link>
                            </li>
                        ) : (
                            <li className="nav-item "  key={index}>
                                <a
                                    className="nav-link"
                                    data-toggle="collapse"
                                    href={`#${menu.name}`}
                                    aria-expanded="false"
                                    aria-controls="ui-basic"
                                >
                                    <i className="icon-layout menu-icon"></i>
                                    <span className="menu-title" style={{fontWeight:'bold'}}>{menu.name}</span>
                                    <i className="menu-arrow"></i>
                                </a>
                                <div className={`collapse ${currentpath === menu.path ? "active" : ""
                                    }`} id={menu.name}>
                                    <ul className="nav flex-column sub-menu">
                                        {
                                            menu.children.map((child, cIndex) => (
                                                <li className="nav-item" key={cIndex}> <Link to={isLogined ? child.path:"/"} className="nav-link">{child.name}</Link></li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </li>

                        )
                    )}
                </ul>
            </nav>
        </React.Fragment>
        
    );
}
export default Sidebar;