import Navbar from './Navbar';
// import Themesettings from './Themesettings';
import Sidebar from './Sidebar';
// import Footer from './Footer';
import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router';
import { Routes } from './sidemenu/Routes';
// import Login from '../views/Auth/Login';
// import { useHistory } from "react-router-dom";


function Baselayout(props) {

  return (
    <React.Fragment>
      <div className="container-scroller">
        <Navbar setLoggedValue={props.setLoggedValue}/>
        <div className="container-fluid page-body-wrapper">
          {/* <Themesettings /> */}
          <Sidebar />
          <div className="main-panel">
            <div className="content-wrapper">
              <Suspense fallback={<div className="jumping-dots-loader">
                          <span></span>
                          <span></span>
                          <span></span>
                        </div>} >
                <Switch>
                  {
                    Routes.map((route, index) => {
                      return ((
                        <Route
                          path={route.path}
                          key={index}
                          component={route.component}
                          exact={route.exact}
                        />
                      ))
                    })
                  }
                </Switch>
              </Suspense>
            </div>
            {/* <Footer /> */}
          </div>


        </div>

      </div>
    </React.Fragment>
  );
}

export default Baselayout;
