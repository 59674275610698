export const Menu = [
    {
        name: "Dashboard",
        icon: 'fa fa-tachometer menu-icon',
        fixmenu: true,
        path: '/Dashboard',
        children: []
    },
    {
        name: "Parties",
        icon: 'fa fa-users menu-icon',
        fixmenu: true,
        path: '/Parties',
        children: []
    },
    {
        name: "Item",
        icon: 'fa fa-cubes menu-icon',
        fixmenu: true,
        path: '/Items',
        children: []
    },
    {
        name: "Purchase",
        icon: 'fa fa-shopping-cart menu-icon',
        fixmenu: true,
        path: '/Purchase',
        children: []
    },
    {
        name: "Sales",
        icon: 'fa fa-shopping-bag menu-icon',
        fixmenu: true,
        path: '/Sales',
        children: []
    },
    {
        name: "Stock Summary",
        icon: 'fa fa-line-chart menu-icon',
        fixmenu: true,
        path: '/StockSummary',
        children: []
    },
    {
        name: "Monthly Sheet",
        icon: 'fa fa-calendar menu-icon',
        fixmenu: true,
        path: '/MonthlySheet',
        children: []
    },
    
    // {
    //     name: "Reports",
    //     icon: 'icon-head menu-icon',
    //     fixmenu: true,
    //     path: '/Reports',
    //     children: []
    // },
    {
        name: "MasterDataSet",
        icon: "menu-icon fa fa-cogs",
        fixmenu: false,
        children: [
            // {
            //     name: "TodayRate",
            //     path: '/Price',
            //     icon: 'icon-head menu-icon'
            // },
            {
                name: "ItemCategory",
                path: '/ItemCategoryList',
                icon: 'icon-head menu-icon'
            },
           
        ]
    },
    // {
    //     name:"Report",
    //     icon:"menu-icon fa fa-cogs" ,
    //     path:'/sampleList',

    //     fixmenu:false,
    //     children:[
    //         {
    //             name:"Unit",
    //             // path:'/MasterDataSet/unit_List',
    //             path:'/MasterDataSet/unitList',
    //             icon:'icon-head menu-icon'
    //         },
    //         {
    //             name:"ItemCategory",
    //             path:'/MasterDataSet/ItemCategoryList',
    //             // path:'/MasterDataSet/ItemCategoryList',
    //             icon:'icon-head menu-icon'
    //         },
    //     ]
    // }
];